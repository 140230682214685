import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Button,
  Table,
  Popover,
  Space,
  Empty,
  Typography,
  Popconfirm,
  Badge,
} from 'antd';

import {
  selectors,
  ForwardContract,
  closeForwardContract,
  PAUSED,
  FROZEN,
  MARKET_ORDER,
  STOP_LOSS,
  TAKE_PROFIT,
} from 'state';
import { formatters } from 'services';
import { cashExchange, useReportingCurrency } from '../../state/app/utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import FwdOdaModal from './FwdOdaModal';

type props = {
  visible: boolean;
  hide: () => void;
  id: number;
  player_id?: number;
};

type displayContract = {
  contract: ForwardContract;
  key: string;
  name: string;
  open_date: string;
  currency: string;
  open_price: string;
  amount: string;
  reporting_unrealized_pnl: string;
  value: string;
};
// FWD CONTRACTS
const ContractsModal: React.FC<props> = ({ visible, hide, id, player_id }) => {
  const dispatch = useDispatch();
  const contracts = useSelector(
    selectors.forwardContractsOfInstrumentList(id, player_id)
  );
  const fin = useSelector(selectors.financialInstrumentById(id, player_id));
  const asset = useSelector(selectors.underlyingAssetOfInstrument(fin));
  const currency = useSelector(selectors.currencyById(asset.base_currency));
  const session = useSelector(selectors.activeSession);
  const reportingCurrency = useReportingCurrency(session.id);
  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);
  const scenarioDate = new Date(session.scenario_date.split('T')[0]);
  const [visibleOdaModal, setVisibleOdaModal] = useState(false);
  const [selectedContract, setSelectedContract] = useState<
    displayContract | undefined
  >(undefined);

  const data: displayContract[] = contracts
    .map(contract => {
      const reporting_value = cashExchange(
        asset.base_currency,
        session.reporting_currency,
        contract.amount,
        0,
        assetList,
        currencyList
        // contract.open_price
      );

      return {
        contract: contract,
        key: contract.open_date,
        name: asset.name,
        open_date: contract.open_date.split('T')[0],
        currency: currency.symbol,
        open_price: formatters.priceFormat(
          contract.open_price,
          asset.ticker,
          asset.resourcetype
        ),
        amount: formatters.commas2Digits(contract.amount),
        reporting_unrealized_pnl: formatters.commas2Digits(
          contract.reporting_unrealized_pnl
        ),
        value: formatters.commas2Digits(-1 * reporting_value),
      };
    })
    .filter(contract => {
      // if contract doesn't have a delivery_timestamp it means it has not yet matured
      return contract.contract.delivery_timestamp === null;
    });

  const infoContent = (
    <div>
      <p>Info 1</p>
      <p>Info 2</p>
    </div>
  );
  const ActionColumnTitle = <Space>Action/Status</Space>;
  const handleCloseContract = (contract: displayContract) => {
    dispatch(closeForwardContract({ contract_id: contract.contract.id }));
  };
  const ActionButton = (contract: displayContract) => {
    const isClosingContract = contract.contract.closing_contract_for !== null;
    const isClosed = contract.contract.closed_by !== null;
    const sessionPaused = session.status === PAUSED;
    if (isClosingContract) {
      if (contract.contract.type === MARKET_ORDER) {
        return `Offsetting #${contract.contract.closing_contract_for}`;
      } else if (contract.contract.type === STOP_LOSS) {
        return `Stop loss for #${contract.contract.closing_contract_for}`;
      } else if (contract.contract.type === TAKE_PROFIT) {
        return `Take profit for #${contract.contract.closing_contract_for}`;
      }
    }
    if (isClosed) {
      return `Offset by #${contract.contract.closed_by}`;
    }
    const title = (
      <div>
        <div>Are you sure?</div>
        <div style={{ width: 250 }}>
          Offsetting a forward contract will open a new opposing contract in
          order to offset the initial contract risk.
        </div>
      </div>
    );
    if (player_id) return '-';
    return (
      <div>
        <Popconfirm
          title={title}
          onConfirm={() => {
            handleCloseContract(contract);
          }}
          disabled={sessionPaused || session.status === FROZEN}
        >
          <Button disabled={sessionPaused || session.status === FROZEN}>
            Offset contract
          </Button>
        </Popconfirm>
        <Button
          style={{ marginLeft: 10 }}
          disabled={sessionPaused || session.status === FROZEN}
          onClick={() => {
            setSelectedContract(contract);
            setVisibleOdaModal(true);
          }}
        >
          <div>
            {/*ODA*/}
            <Badge
              // style={{ marginLeft: 10 }}
              status={contract.contract.oda ? 'processing' : 'default'}
              color={contract.contract.oda ? 'green' : 'orange'}
              text={contract.contract.oda ? 'ODA' : 'ODA'}
            />
          </div>
        </Button>
      </div>
    );
  };

  const columns: ColumnsType<displayContract> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Open',
      dataIndex: 'open_date',
    },
    {
      title: 'Maturity',
      dataIndex: 'contract',
      render: (text, record) => {
        return record.contract ? record.contract.maturity_date : null;
      },
    },
    {
      title: 'CCY',
      dataIndex: 'currency',
    },
    {
      title: 'Open Price',
      dataIndex: 'open_price',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: `Unrealized, ${reportingCurrency.symbol}`,
      dataIndex: 'reporting_unrealized_pnl',
      render: (text, record) => {
        return record.contract
          ? formatters.commasNoDigits(record.contract.reporting_unrealized_pnl)
          : null;
      },
    },
    {
      title: `Realized, ${reportingCurrency.symbol}`,
      dataIndex: 'contract',
      render: (text, record) => {
        return record.contract
          ? formatters.commasNoDigits(record.contract.reporting_realized_pnl)
          : null;
      },
    },
    {
      title: `Value, ${reportingCurrency.symbol}`,
      dataIndex: 'value',
    },
  ];
  // {
  //   title: ActionColumnTitle,
  //   render: (val: any, record: displayContract) => ActionButton(record),
  // },
  // if (player_id === undefined) {
  columns.push({
    title: ActionColumnTitle,
    render: (val: any, record: displayContract) => ActionButton(record),
  });
  // }
  columns.push({
    title: 'ID',
    dataIndex: 'contract',
    render: (val, record) => record.contract.id,
  });
  // ];
  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {`You don't have any active forward contracts yet.`}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  return (
    <Modal
      visible={visible}
      title={`Your active ${asset.name} Forward Contracts`}
      onOk={hide}
      onCancel={hide}
      width={'70%'}
    >
      <Table
        dataSource={data}
        columns={columns}
        bordered
        locale={noDataBehaviour}
      ></Table>
      {visibleOdaModal && (
        <FwdOdaModal
          visible={visibleOdaModal}
          hide={() => setVisibleOdaModal(false)}
          contract_id={selectedContract?.contract.id}
          instrument_id={fin.id}
        />
      )}
    </Modal>
  );
};

export default ContractsModal;
