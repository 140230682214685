import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  message,
  notification,
  Popover,
  Table,
  Typography,
} from 'antd';

import {
  fetchAssets,
  selectors,
  updateAdminEquity,
  fetchEquities,
  EquityBeta,
} from 'state';
import { columnSearchWidget } from 'components';
import { TextType, IndexFundSelect } from './adminInputs';
import { InfoCircleOutlined } from '@ant-design/icons';
import { API_URL } from 'settings';

const { Text } = Typography;

const EquitiesTab: React.FC = () => {
  const dispatch = useDispatch();
  const assets = useSelector(selectors.underlyingAssetsList, () => true);
  const equities = useSelector(selectors.equityList);
  const token = useSelector(selectors.token);

  useEffect(() => {
    if (!assets.length) {
      dispatch(fetchAssets({}));
    }
    if (!equities.length) {
      dispatch(fetchEquities({}));
    }
  }, [dispatch]);

  const update = (record: any, key: any) => (val: any) => {
    if (val === '') {
      val = null;
    }
    dispatch(updateAdminEquity({ id: record.id, [key]: val }));
  };

  const [data, setData] = useState<FormData | undefined>(undefined);

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const raw = e.target.files as FileList;
    const formData = new FormData();
    if (raw[0]) {
      formData.append('betas_csv', raw[0], raw[0].name);
    }
    setData(formData);
  };

  const uploadHandler = async () => {
    fetch(`${API_URL}/admin/upload_betas/`, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then(res => {
        if (res.status === 200) {
          message.success('Successful operation');
        } else {
          message.error('An error has occurred');
        }
        return res.json();
      })
      .then(
        (data: {
          data: EquityBeta[];
          errors: { entry: string; error: string }[];
        }) => {
          if (!data || !data.errors || data.errors.length === 0) {
            return;
          }

          notification.error({
            key: `upload_betas`,
            message: `Upload BETAs`,
            description: (
              <div style={{ maxHeight: 400, overflowY: 'auto' }}>
                {data?.errors.map((e, index) => {
                  return (
                    <div
                      key={index}
                      style={{ fontSize: '90%', display: 'flex', gap: 5 }}
                    >
                      <Text>{e.entry}</Text>
                      <Text style={{ color: '#f5222d' }}>{e.error}</Text>
                    </div>
                  );
                })}
              </div>
            ),
          });
        }
      )
      .catch(() => {
        message.error('An error has occurred');
      });
  };

  return (
    <div>
      <div>
        <Popover
          placement={'right'}
          content={<Text>ric,beta</Text>}
          title=".csv header must look like:"
        >
          <InfoCircleOutlined />
        </Popover>

        <input
          style={{
            marginLeft: '8px',
            marginBottom: '12px',
            display: 'inline-block',
            width: 200,
          }}
          type="file"
          id="betas_csv"
          name="betas_csv"
          accept=".csv"
          onChange={handleFileSelection}
        />

        <Button disabled={data === undefined} onClick={uploadHandler}>
          Upload
        </Button>
      </div>
      <Table
        loading={!equities.length}
        dataSource={equities}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
        columns={[
          { title: 'ID', dataIndex: 'id' },
          {
            title: 'Ticker',
            dataIndex: 'ticker',
            render: (val, record) => TextType(val, update(record, 'ticker')),
            sorter: (a, b) =>
              a.ticker < b.ticker ? -1 : a.ticker > b.ticker ? 1 : 0,
            ...columnSearchWidget({ dataIndex: 'ticker' }),
          },
          {
            title: 'RIC',
            dataIndex: 'ric',
            render: (val, record) => TextType(val, update(record, 'ric')),
            sorter: (a, b) => (a.ric < b.ric ? -1 : a.ric > b.ric ? 1 : 0),
            ...columnSearchWidget({ dataIndex: 'ric' }),
          },
          {
            title: 'Name',
            dataIndex: 'name',
            render: (val, record) => TextType(val, update(record, 'name')),
            sorter: (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0),
            ...columnSearchWidget({ dataIndex: 'name' }),
          },
          {
            title: 'Sector',
            dataIndex: 'sector',
            render: (val, record) => TextType(val, update(record, 'sector')),
          },
          {
            title: 'Industry',
            dataIndex: 'industry',
            render: (val, record) => TextType(val, update(record, 'industry')),
          },
          {
            title: 'Index Fund',
            dataIndex: 'index_fund',
            render(_, record) {
              return (
                <IndexFundSelect
                  defaultVal={record.index_fund}
                  update={update(record, 'index_fund')}
                />
              );
            },
          },
          {
            title: 'Beta',
            children: [
              {
                title: 'Value',
                render(_, record) {
                  return record?.beta_instance?.beta || '-';
                },
              },
              {
                title: 'Timestamp',
                render(_, record) {
                  return record?.beta_instance?.timestamp || '-';
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default EquitiesTab;
