import React from 'react';
import { useSelector } from 'react-redux';

import { Empty, Spin, Table, Typography } from 'antd';

import { connectToAdminSocket, selectors, TRANSACTION_TYPES } from 'state';
import { formatters } from 'services';
import {
  financialInstruments,
  fwdContracts,
} from '../../../state/app/selectors';
import { LoadingOutlined } from '@ant-design/icons';

const FxForwardsBlotter: React.FC = () => {
  const contracts = useSelector(selectors.fwdContracts);
  const session = useSelector(selectors.activeSession);
  const currencies = useSelector(selectors.currencies);
  const financialInstruments = useSelector(selectors.financialInstruments);
  const assets = useSelector(selectors.underlyingAssets);
  const deliveredContracts = contracts.filter(contract => {
    return contract.delivery_timestamp !== null;
    // const scenarioDate = new Date(session.scenario_date).setHours(0, 0, 0, 0);
    // const maturityDate = new Date(contract.maturity_date).setHours(0, 0, 0, 0);
    // return maturityDate <= scenarioDate;
  });
  const forwardContracsFetching = useSelector(
    selectors.fwdContractsFetching(undefined)
  );
  const data = deliveredContracts
    .map(contract => {
      const fin = financialInstruments[contract.financial_instrument];
      const asset = assets[fin.underlying_asset];
      return {
        ...contract,
        transaction: TRANSACTION_TYPES[contract.transaction],
        underlying_asset: asset.name,
        base_currency: currencies[asset.base_currency].symbol,
        variable_currency: currencies[asset.variable_currency].symbol,
        open_date: contract.open_date.split('T')[0],
        ticker: asset.ticker,
        resource_type: asset.resourcetype,
      };
    })
    .sort((a, b) => (a.maturity_date < b.maturity_date ? 1 : -1));
  // .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          {`You don't have any matured forward contracts yet.`}
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  if (forwardContracsFetching) {
    return (
      <Spin indicator={<LoadingOutlined style={{ fontSize: 150 }} spin />} />
    );
  }

  return (
    <div>
      <Table
        rowKey="id"
        bordered
        dataSource={data}
        pagination={false}
        locale={noDataBehaviour}
        columns={[
          {
            title: 'Asset',
            dataIndex: 'underlying_asset',
          },
          {
            title: 'Transaction',
            dataIndex: 'transaction',
          },
          {
            title: 'Base Currency',
            dataIndex: 'base_currency',
          },
          {
            title: 'Variable Currency',
            dataIndex: 'variable_currency',
          },
          {
            title: 'Open',
            dataIndex: 'open_date',
          },
          {
            title: 'Maturity',
            dataIndex: 'maturity_date',
          },
          {
            title: 'Quantity',
            dataIndex: 'amount',
            align: 'right',
            render: formatters.commasNoDigits,
          },
          {
            title: 'Open fwd. rate',
            dataIndex: 'open_price',
            render: (val, record) =>
              formatters.priceFormat(val, record.ticker, record.resource_type),
          },
          {
            title: 'Close spot rate',
            dataIndex: 'close_price',
            render: (val, record) =>
              formatters.priceFormat(val, record.ticker, record.resource_type),
          },
          {
            title: 'Realized, ' + currencies[session.reporting_currency].symbol,
            dataIndex: 'reporting_realized_pnl',
            render: formatters.commas2Digits,
          },
          {
            title: 'Offset ',
            dataIndex: 'contract',
            render: (val, record) => {
              if (
                record.closing_contract_for === null &&
                record.closed_by === null
              ) {
                return 'Full length';
              }
              if (record.closing_contract_for !== null) {
                return `Offsetting #${record.closing_contract_for}`;
              }
              return `Offset by #${record.closed_by}`;
            },
          },
          {
            title: 'ID',
            dataIndex: 'id',
          },
          // {
          //   title: 'Value',
          //   dataIndex: 'value',
          //   align: 'right',
          //   render: formatters.commasNoDigits,
          // },
        ]}
      />
    </div>
  );
};

export default FxForwardsBlotter;
