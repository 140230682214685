import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'reusable';

import { Badge, Menu } from 'antd';
import { UserOutlined, WarningTwoTone } from '@ant-design/icons';

import { EmptyHeader } from 'components';
import {
  FINISHED,
  HISTORICAL_SCENARIO,
  logout,
  REAL_TIME_SCENARIO,
  selectors,
} from 'state';
import { failedExecutions } from '../../state/app/selectors';

const MyHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { status, scenario_type, allow_individual_benchmark } = useSelector(
    selectors.activeSession
  );
  const assets = useSelector(selectors.underlyingAssets);
  const player = useSelector(selectors.player);
  const selectedPerson = useSelector(selectors.selectedPerson);
  const username = useSelector(selectors.username);
  const failedExecutions = useSelector(selectors.failedExecutions);
  const { url } = useRouteMatch();

  const isFinished = status === FINISHED;

  const isRealTime = scenario_type === REAL_TIME_SCENARIO;

  const sessionHasNoEquities = Object.values(assets).every(
    asset => asset.resourcetype !== 'Equity'
  );
  let errorBadge: number | JSX.Element = 0;
  if (failedExecutions !== undefined && failedExecutions.length !== 0) {
    errorBadge = <WarningTwoTone twoToneColor="#FFBF00" />;
  }

  const showEquitiesResearch = isRealTime && !sessionHasNoEquities;

  return (
    <EmptyHeader defaultKey="graph" home={`${url}`}>
      {/*{!isFinished && (*/}
      {/*  <Menu.Item key="risk-profile">*/}
      {/*    <Link to={`${url}/risk-profile`}>Risk Profile</Link>*/}
      {/*  </Menu.Item>*/}
      {/*)}*/}

      {!isFinished && (
        <Menu.Item key="markets">
          <Link to={`${url}/markets`}>Markets</Link>
        </Menu.Item>
      )}

      {/*{showEquitiesResearch && (*/}
      {/*  <Menu.Item key="equities-research">*/}
      {/*    <Link to={`${url}/equities-research`}>Equities Research</Link>*/}
      {/*  </Menu.Item>*/}
      {/*)}*/}

      <Menu.Item key="blotter">
        <Link to={`${url}/blotter`}>Blotter</Link>
      </Menu.Item>
      <Menu.Item key="open-orders">
        <Link to={`${url}/open-orders`}>Open Orders</Link>
        <Badge count={errorBadge} offset={[15, -10]}></Badge>
      </Menu.Item>
      {scenario_type === HISTORICAL_SCENARIO && (
        <Menu.Item key="auctions">
          <Link to={`${url}/auctions`}>Auctions</Link>
          <Badge count={errorBadge} offset={[15, -10]}></Badge>
        </Menu.Item>
      )}
      {scenario_type === HISTORICAL_SCENARIO && !isFinished && (
        <Menu.Item key="news">
          <Link to={`${url}/news`}>News</Link>
        </Menu.Item>
      )}
      <Menu.Item key="portfolio">
        <Link to={`${url}/portfolio`}>Portfolio</Link>
      </Menu.Item>
      {allow_individual_benchmark && (
        <Menu.Item key="benchmarks">
          <Link to={`${url}/benchmarks`}>Benchmarks</Link>
        </Menu.Item>
      )}
      <Menu.Item key="metrics">
        <Link to={`${url}/metrics`}>Metrics</Link>
      </Menu.Item>
      {/*{!isFinished && (*/}
      {/*  <Menu.Item key="capital-usage">*/}
      {/*    <Link to={`${url}/capital-usage`}>Capital Usage</Link>*/}
      {/*  </Menu.Item>*/}
      {/*)}*/}
      {scenario_type === HISTORICAL_SCENARIO && (
        <Menu.Item key="macro-background">
          <Link to={`${url}/macro-background`}>Macro Background</Link>
        </Menu.Item>
      )}
      {isFinished && (
        <Menu.Item key="individual_report">
          <Link to={`${url}/individual_report`}>Individual Report</Link>
        </Menu.Item>
      )}
      {/*<Menu.Item key="insights">*/}
      {/*  <Link to={`${url}/insights`}>Portfolio Insights</Link>*/}
      {/*</Menu.Item>*/}
      <Menu.SubMenu
        title={
          <>
            <UserOutlined />
            {selectedPerson?.name || player?.name}
          </>
        }
        style={{ float: 'right' }}
      >
        <Menu.Item>
          <Link to={`${url}/settings`}>Settings</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/dashboard`}>Exit session</Link>
        </Menu.Item>
        <Menu.Item onClick={() => dispatch(logout({}))}>Logout</Menu.Item>
      </Menu.SubMenu>
      {username ? (
        <Menu.Item style={{ float: 'right' }}>{username}</Menu.Item>
      ) : (
        ''
      )}
    </EmptyHeader>
  );
};

export default MyHeader;
