import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import {
  Modal,
  InputNumber,
  DatePicker,
  Form,
  Button,
  Select,
  Switch,
  Input,
} from 'antd';

import {
  createSession,
  RISK_APPROACHES,
  CASH_APPROACH,
  RISK_WEIGHTED_APPROACH,
  TRADING_TIME_TYPES,
  SCENARIO_TYPES,
  HISTORICAL_SCENARIO,
  REAL_TIME_SCENARIO,
  REPORTING_CURRENCIES,
  selectors,
} from 'state';
import { PercentInput } from 'components';
import { workdayCount } from '../../state/app/utils';

type props = {
  visible: boolean;
  hide: () => void;
  klassId: number;
};

const getNow = () => moment().startOf('day');

const initialValues = {
  scenarioType: 0,
  riskApproach: 1,

  limitOrderCollateral: 10,
  riskWeightedLimit: 100_000_000,
  transactionFee: 0,
  short_sell_leverage: 30,
  fxLeverage: 100,
  bondLeverage: 100,
  initialAmount: 100_000_000,
  reportingCurrency: 'USD',
  allowShortSell: true,
  clientMoney: false,
  metricsInterval: 24,
  tradingTimeType: 0,
  dates: [getNow().subtract(1, 'months'), getNow()],
  newsImportances: '1,2',
  frequency: 1,
  delay: 0,
};

const SessionSettings: React.FC<props> = ({ visible, hide, klassId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [riskApproach, setRiskApproach] = useState(initialValues.riskApproach);
  const [scenarioType, setScenarioType] = useState(initialValues.scenarioType);

  const [sessionDuration, setSessionDuration] = useState('-');
  const historicalStep = useSelector(selectors.historicalStep);

  const computeDuration = () => {
    const formDates: [Moment, Moment] = form.getFieldValue('dates');
    const formFrequency: number = form.getFieldValue('frequency');
    let sessionSeconds = 0;
    if (!formFrequency || typeof formFrequency !== 'number') {
      setSessionDuration('-');
      return;
    }

    if (formDates?.length === 2) {
      const workdays = workdayCount(formDates[0], formDates[1], formFrequency);
      sessionSeconds = workdays * historicalStep;
    }

    const sessionHours = Math.floor(sessionSeconds / 60 / 60);
    const sessionMinutes = Math.floor((sessionSeconds / 60) % 60);
    setSessionDuration(`${sessionHours}h:${sessionMinutes}m`);
  };

  return (
    <Modal
      visible={visible}
      onCancel={hide}
      title=""
      footer={[
        <Button type="primary" key="submit" onClick={() => form.submit()}>
          Create
        </Button>,
      ]}
    >
      <Form
        form={form}
        initialValues={initialValues}
        labelCol={{ span: 8 }}
        onValuesChange={computeDuration}
        onFinish={() => {
          const {
            scenarioType,
            limitOrderCollateral,
            initialAmount,
            metricsInterval,
            dates,
            short_sell_leverage,
            fxLeverage,
            bondLeverage,
            riskWeightedLimit,
            riskApproach,
            reportingCurrency,
            tradingTimeType,
            allowShortSell,
            newsImportances,
            frequency,
            delay,
            clientMoney,
          } = form.getFieldsValue();

          dispatch(
            createSession({
              klass_id: klassId,
              scenario_type: scenarioType,
              limit_order_collateral:
                (limitOrderCollateral || initialValues.limitOrderCollateral) /
                100,
              transaction_fee: 0,
              short_sell_leverage:
                (short_sell_leverage || initialValues.short_sell_leverage) /
                100,
              fx_leverage: (fxLeverage || initialValues.fxLeverage) / 100,
              bond_leverage: (bondLeverage || initialValues.bondLeverage) / 100,
              starting_cash_amount:
                initialAmount || initialValues.initialAmount,
              reporting_currency:
                reportingCurrency || initialValues.reportingCurrency,
              metrics_interval: metricsInterval,
              start_date: (dates || [])[0]?.toISOString(),
              end_date: (dates || [])[1]?.toISOString(),
              risk_weighted_limit:
                riskWeightedLimit || initialValues.riskWeightedLimit,
              risk_approach: riskApproach,
              trading_time_type: tradingTimeType,
              spread_widener: 0,
              allow_short_sell: allowShortSell,
              client_money: clientMoney || initialValues.clientMoney,
              news_importances:
                newsImportances?.replaceAll(' ', '').split(',') || [],
              frequency: frequency || initialValues.frequency,
              delay_minutes_left: delay || initialValues.delay,
            })
          );
          hide();
        }}
      >
        <Form.Item
          name="scenarioType"
          label="Scenario Type"
          rules={[{ required: true }]}
        >
          <Select
            onChange={(a: number) => setScenarioType(a)}
            style={{ width: 200 }}
          >
            <Select.Option value={0}>{SCENARIO_TYPES[0]}</Select.Option>
            <Select.Option value={1}>{SCENARIO_TYPES[1]}</Select.Option>
          </Select>
        </Form.Item>
        {scenarioType === HISTORICAL_SCENARIO && (
          <>
            <span style={{ paddingLeft: 157 }}>
              Session duration: {sessionDuration}
            </span>
            <Form.Item
              name="dates"
              label="Historical Period"
              rules={[{ required: true }]}
            >
              <DatePicker.RangePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item
              name="frequency"
              label="Frequency (Days)"
              rules={[
                { required: true },
                { type: 'number', message: 'Please input a valid number!' },
              ]}
            >
              <InputNumber min={1} max={31} />
            </Form.Item>
            <Form.Item
              name="delay"
              label="Delay (mins.)"
              rules={[
                { required: true },
                { type: 'number', message: 'Please input a valid number!' },
              ]}
            >
              <InputNumber min={0} max={31} />
            </Form.Item>
            <Form.Item
              valuePropName="checked"
              name="clientMoney"
              label="Client Money"
              rules={[{ required: true }]}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="newsImportances"
              label="News"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </>
        )}
        {scenarioType === REAL_TIME_SCENARIO && (
          <Form.Item
            name="tradingTimeType"
            label="Trading Time"
            rules={[{ required: true }]}
          >
            <Select style={{ width: 200 }}>
              <Select.Option value={0}>{TRADING_TIME_TYPES[0]}</Select.Option>
              <Select.Option value={1}>{TRADING_TIME_TYPES[1]}</Select.Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="riskApproach"
          label="Risk approach"
          rules={[{ required: true }]}
        >
          <Select
            onChange={(a: number) => setRiskApproach(a)}
            style={{ width: 200 }}
          >
            <Select.Option value={0}>{RISK_APPROACHES[0]}</Select.Option>
            <Select.Option value={1}>{RISK_APPROACHES[1]}</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="reportingCurrency"
          label="Reporting currency"
          rules={[{ required: true }]}
        >
          <Select style={{ width: 200 }}>
            {REPORTING_CURRENCIES.map(ccy => {
              return (
                <Select.Option value={ccy} key={ccy}>
                  {ccy}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        {riskApproach === CASH_APPROACH && (
          <>
            <Form.Item
              name="initialAmount"
              label="Initial Amount"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={value =>
                  ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => `${value?.replace(/\$\s?|(,*)/g, '')}`}
                style={{ width: 130 }}
              />
            </Form.Item>
            <Form.Item
              name="limitOrderCollateral"
              label="Limit Order Collateral"
              rules={[{ required: true }]}
            >
              <PercentInput />
            </Form.Item>
            <Form.Item
              name="short_sell_leverage"
              label="Short Sell Leverage"
              rules={[{ required: true }]}
            >
              <PercentInput />
            </Form.Item>
            <Form.Item
              name="fxLeverage"
              label="FX Leverage"
              rules={[{ required: true }]}
            >
              <PercentInput />
            </Form.Item>
            <Form.Item
              name="bondLeverage"
              label="Bond Leverage"
              rules={[{ required: true }]}
            >
              <PercentInput />
            </Form.Item>
          </>
        )}
        {riskApproach === RISK_WEIGHTED_APPROACH && (
          <>
            <Form.Item
              name="riskWeightedLimit"
              label="Risk weighted limit"
              rules={[{ required: true }]}
            >
              <InputNumber
                formatter={value =>
                  ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => `${value?.replace(/\$\s?|(,*)/g, '')}`}
                style={{ width: 130 }}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          valuePropName="checked"
          name="allowShortSell"
          label="Allow Short Sell"
          rules={[{ required: true }]}
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SessionSettings;
