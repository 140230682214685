import { appManager, AppState } from '../manager';
import current from 'immer';
import {
  addElementToIndexedArray,
  arrayToIndexedObject,
  makeAPIProp,
} from 'services/indexedObject';

import * as types from '../types';
import { TRANSACTION_TYPES } from '../constants';
import { authManager, utils } from '../../index';
import Cookies from 'js-cookie';
import {
  AssetClassOptions,
  MarginOptions,
  MarketHistory,
  TBillContractType,
  UnderlyingAsset,
  Wallet,
} from '../types';
import {
  auctionFinishedSocketType,
  AuctionResponseType,
  createAuctionType,
  IssueSecurityType,
  receiveCreatedAuctionType,
  sendAuctionResponseType,
  YieldsType,
} from './types';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { playerById, wallets } from '../selectors';
import { formatters } from 'services';

// NOTE TO DEVELOPER:
//  PAGINATED SESSIONS ARE ONLY USED FOR PERFORMANCE REASONS IN THE ADMIN TAB
//  ALL player and / or relevant data is to be stored in the INDEXED SESSIONS
//  Duplicating data in redux is not a good idea
// TODO: When fetching paginated sessions, get the data and store it in the indexed sessions, then store the ids in the
//  paginated state. Therefore, removing and duplicated data.
//  Consider caching pages and creating cache clearing for specific actions like: DELETE, UPDATE, CREATE OR
//  create a system where paginated api provides only ids and then the frontend requests the ids which are not stored
//  in state.

export const createAuction = appManager.createApi<
  createAuctionType,
  unknown,
  AppState
>('CREATE_AUCTION', {
  path: '/auctions/create_auction',
  method: 'POST',
  successReducer(state, result) {
    // console.log(result);
  },
});

export const issueSecurity = appManager.createApi<
  IssueSecurityType,
  unknown,
  AppState
>('ISSUE_SECURITY', {
  path: '/auctions/issue_security',
  method: 'POST',
  successReducer(state, result) {
    // console.log(result);
  },
});

export const fetchAuctions = appManager.createApi<
  { session_id: number },
  receiveCreatedAuctionType[],
  AppState
>('LIST_AUCTIONS', {
  path: '/auctions/list_auctions_by_session',
  method: 'POST',
  successReducer(state, result) {
    // console.log(result);
    state.auctions = result;
  },
});

export const fetchYields = appManager.createApi<
  {
    session_id: number;
    ccy_symbol?: string;
  },
  YieldsType,
  AppState
>('FETCH_YIELDS', {
  path: '/yields/fetch_todays_historical_yields',
  method: 'POST',
  successReducer(state, result) {
    // console.log(result);
    state.sessions.items[
      state.active_session
    ].todays_historical_yields = result;
  },
});

appManager.createSocketListener<YieldsType, AppState>(
  'new_period_yields',
  (state, result) => {
    console.log('new_periods_yields: ', result);
    state.sessions.items[
      state.active_session
    ].todays_historical_yields = result;
  }
);

appManager.createSocketListener<
  {
    trading_book: number;
    interbank_amount: number;
    total_repoed_amount: number;
    client_interest: number;
    paid_lombard_interest: number;
    paid_repo_interest: number;
    trading_book_pnl: number;
  },
  AppState
>('update_client_money_overview_cards', (state, result) => {
  console.log('update_client_money_overview_cards: ', result);
  state.player.item.trading_book = result.trading_book;
  state.player.item.interbank_amount = result.interbank_amount;
  state.player.item.total_repoed_amount = result.total_repoed_amount;
  state.player.item.client_interest = result.client_interest;
  state.player.item.paid_lombard_interest = result.paid_lombard_interest;
  state.player.item.paid_repo_interest = result.paid_repo_interest;
  state.player.item.trading_book_pnl = result.trading_book_pnl;
});

export const sendAuctionResponse = appManager.createApi<
  sendAuctionResponseType,
  unknown,
  AppState
>('RESPOND_TO_AUCTION', {
  path: '/auctions/create_player_auction_response',
  method: 'POST',
  successReducer(state, result) {
    // console.log(result);
    // state.auctions = result;
  },
});
appManager.createSocketListener<receiveCreatedAuctionType, AppState>(
  'new_auction_created',
  (state, result) => {
    // console.log('ws auction: ', result);
    state.auctions.push(result);
    if (state.player.item.id !== undefined) {
      // if message is received by a player  (not by trainer), send a notification
      notification.warn({
        message: 'The Central Bank just launched a new auction!',
      });
    }
  }
);

appManager.createSocketListener<
  {
    auction: AuctionResponseType;
    wallet: Wallet;
  },
  AppState
>('new_auction_response', (state, result) => {
  // console.log('ws auction response: ', result);
  const response_for_which_auction_id = result.auction.auction;
  const for_this_auction = state.auctions.find(
    auction => auction.id === response_for_which_auction_id
  );
  // const player = useSelector(playerById(result.player));
  if (for_this_auction !== undefined) {
    if (!for_this_auction.responses) {
      // Initialize responses as an empty array if it's undefined, so you can push to it
      for_this_auction.responses = [];
    }
    console.log(for_this_auction);
    console.log(for_this_auction.responses);
    for_this_auction.responses.push(result.auction);
    // const bid_unit_numerical = result.bid_unit;
    let bid_unit_string;
    // sorry, this was coded during a weekend all-nighter :(
    // if (bid_unit_numerical === 0) {
    //   bid_unit_string = 'million/s';
    // } else if (bid_unit_numerical === 1) {
    //   bid_unit_string = 'billion/s';
    // } else if (bid_unit_numerical === 2) {
    //   bid_unit_string = 'trillion/s';
    // }
    if (state.player.item.id === undefined) {
      // means this logic should only happen in the trainer dashboard
      notification.info({
        message: 'New offer received!',
        description: `Team ${
          result.auction.player_name
        } just bid ${formatters.commasNoDigits(
          result.auction.initial_bid_value
        )} ${result.auction.currency_symbol} on ${
          for_this_auction.auction_series
        }`,
        duration: 6,
      });
    } else {
      // if message is received by a player  (not by trainer)
      const index = state.player.item.wallets.findIndex(
        wallet => wallet.id === result.wallet.id
      );
      state.player.item.wallets[index] = result.wallet;
    }
  }
  // state.auctions.push(result);
  // if (state.player.item.id !== undefined) {
  //   // if message is received by a player  (not by trainer), send a notification
  //   let wallet = state.player.item.wallets.find(
  //     wallet => wallet.id === result.wallet.id
  //   );
  //   wallet = result.wallet;
  // }
});

appManager.createSocketListener<auctionFinishedSocketType, AppState>(
  'auction_closed',
  (state, result) => {
    // update auction status
    const auctionIndex = state.auctions.findIndex(
      auction => auction.id === result.auction_id
    );
    state.auctions[auctionIndex].finished = result.finished;
    state.auctions[auctionIndex].issued_value = result.issued_value;

    // add newly created asset to app.sessions.items[activeSession].underlying_assets
    state.sessions.items[state.active_session].underlying_assets.push(
      result.asset.id
    );
    // add newly created asset to state.underlying_assets
    state.underlying_assets.items[result.asset.id] = result.asset;
    // add market history to asset
    state.underlying_assets.items[result.asset.id].market_history = {
      one_day: [],
      five_days: [],
      one_month: [],
      six_months: [],
      one_year: [],
      three_years: [],
      five_years: [],
    };
    state.underlying_assets.items[result.asset.id].market_history['one_day'] =
      result.market_history;

    // add prices
    state.underlying_assets.items[result.asset.id].bid = result.bid;
    state.underlying_assets.items[result.asset.id].ask = result.ask;

    // add newly created instruments to app.player.item.financial_instruments
    if (state.player.item.id !== undefined) {
      // message received by a player
      const fin = result.fins[state.player.item.id];
      state.player.item.financial_instruments[fin.id] = fin;

      const wallet = result.wallets[state.player.item.id];
      const walletIndex = state.player.item.wallets.findIndex(
        obj => obj.id === wallet.id
      );
      state.player.item.wallets[walletIndex] = wallet;
    }
  }
);

appManager.createSocketListener<{ contracts: TBillContractType[] }, AppState>(
  'update_tbill_contracts',
  (state, result) => {
    // console.log('update_tbill_contracts result: ', result);
    result.contracts.forEach(updated_contract => {
      const fin =
        state.player.item.financial_instruments[
          updated_contract.financial_instrument
        ];
      const existing_contract_id = fin.tbill_contracts.findIndex(
        exiting_contract => exiting_contract.id === updated_contract.id
      );
      fin.tbill_contracts[existing_contract_id] = updated_contract;
    });
  }
);

appManager.createSocketListener<{ updated_tbill: UnderlyingAsset }, AppState>(
  'update_tbill_asset_interplayer_amount',
  (state, result) => {
    // console.log('update_tbill_asset result: ', result);

    state.underlying_assets.items[
      result.updated_tbill.id
    ].available_interplayer_amount =
      result.updated_tbill.available_interplayer_amount;
  }
);
