import { IndexedObject } from 'services/indexedObject';
import {
  ALTERNATIVES_GROUP,
  AssetClass,
  BONDS_GROUP,
  CASH,
  CASH_GROUP,
  CRYPTO_GROUP,
  EQUITIES_GROUP,
  FX_GROUP,
} from '../constants';
import {
  BenchmarkComposition,
  InterBankDeal,
  // LoanRateOfferOptions,
  LoanRateOfferOptions,
  OtherPlayers,
  RFQRequest,
  RFQResponse,
} from '../sessions/types';
import { DepositHistory, DepositInstrument } from '../deposits/types';
import { Dividend, UnderlyingAsset } from '../underlying_assets/types';
import * as types from '../types';
import { API } from 'services';

export type Wallet = {
  id: number;
  currency: number;
  amount: number;
  collateral_amount: number;
  total_currency_interest: number;
  auction_collateral: number;
  client_money: number;
  client_interest: number;
  lombard_interest: number;
};

export type StrategicAssetAllocation = {
  id: number;
  percent: number;
  asset_class: AssetClass;
};

export type TacticalAssetAllocation = {
  id: number;
  asset_class: AssetClass;
  percent: number;
  percent_short: number;
  percent_long: number;
  total: number;
  short: number;
  long: number;
};

// used to display individual player allocations in OverviewTab > PlayersTable
export type PlayerAllocations = {
  Cash?: number;
  Equities: number;
  Bonds: number;
  Alternatives: number;
  FX: number;
  Crypto: number;
};

export type RegionAllocation = {
  id: number;
  chunk: number;
  region: number;
  geography_allocations: GeographyAllocation[];
};

export type GeographyAllocation = {
  id: number;
  geography: string;
  percent: number;
  percent_short: number;
  percent_long: number;
  total: number;
  short: number;
  long: number;
};

export type Metrics = {
  id: number;
  timestamp: string;
  portfolio_volatility: number;
  alpha: number;
  beta: number;
  sharpe_ratio: number;
  treynor_ratio: number;
  var05: number;
  market_index_return: number;
};

export type MetricsSocketResult = Metrics & {
  player: number;
};

// export type Allocations = {
//   Cash: number;
//   Equities: number;
//   Bonds: number;
//   Alternatives: number;
//   FX: number;
//   Crypto: number;
// };

export type PlayerBadge = {
  id: number;
  timestamp: string;
  badge: number;
};

export type ODA = {
  stop_loss: number;
  take_profit: number;
};

export type FinancialInstrument = {
  id: number;
  underlying_asset: number;
  amount: number;
  orders: number[];
  // forward_contracts: ForwardContract[];
  forward_contracts: number[];
  avg_open_price: number;
  reporting_realized_pnl: number;
  reporting_unrealized_pnl: number;
  risk_weight: number;
  pv_limit: number;
  oda: ODA;
  fx_hedge: boolean;
  collateral: number;
  // bonds
  current_accrued_interest: number;
  total_reporting_accrued: number;
  weighted_accrued_days: number;
  // htm/afs bonds
  htm_afs_days_to_coupon: number;
  htm_afs_years_to_maturity: number;
  htm_afs_dv01: number;
  htm_afs_duration: number;
  // repos
  repo_contracts: RepoContractType[];
  repo_maturity_date: string;
  available_repoable_amount: number;
  repoed_amount: number;
  // CDS
  cds_contracts: CDSContractType[];
  // TBill
  tbill_contracts: TBillContractType[];
};

export type ReusableInstrumentTableType = FinancialInstrument &
  UnderlyingAsset & { currency: string };

export type ForwardContract = {
  id: number;
  financial_instrument: number;
  open_date: string;
  maturity_date: string;
  open_price: number;
  close_price: number | null;
  amount: number;
  reporting_realized_pnl: number;
  reporting_unrealized_pnl: number;
  transaction: number;
  delivery_timestamp: string | null;
  closing_contract_for: number | null;
  closed_by: number | null;
  oda: number | null;
  type: number;
};

export type OdaForward = {
  id: number;
  stop_loss_price: number;
  take_profit_price: number;
};

export type CDSContractType = {
  id: number;
  financial_instrument: number;
  underlying_asset_name: string;
  scenario_date_created_timestamp: string;
  cds_contract_maturity_date: string;
  open_rate: number;
  cds_contract_ccy: string;
  // close_price: number | null;
  amount: number;
  cds_contract_reporting_realized_pnl: number;
  reporting_cds_contract_pnl: number; // unrlzd
  cds_contract_current_mkt_value: number;
  premium_paid: number;
  matured: boolean;
  // transaction: number;
  // delivery_timestamp: string | null;
  // closing_contract_for: number | null;
  // closed_by: number | null;
};

export type TBillContractType = {
  id: number;
  financial_instrument: number;
  underlying_asset_name: string;
  scenario_date_maturity_date: string;
  tbill_contract_unrealized_pnl: number;
  tbill_contract_reporting_unrealized_pnl: number;
  tbill_contract_reporting_realized_pnl: number;
  amount: number;
  open_price: number;
  close_price: number;
  status: number;
};

export type RepoContractType = {
  id: number;
  financial_instrument: number;
  repoed_instrument: number;
  repoed_asset: number;
  repoed_quantity: number;
  asset_price_at_repo_open: number;
  repo_contract_open_rate: number;
  repo_contract_maturity_date: string;
  scenario_date_created_date: string;
  underlying_asset_name: string;
  settled: boolean;
  paid_interest: number;
  repo_contract_ccy: string;
  repo_asset_name: string;
  created_timestamp: string;
  settlement_date: string;
};

export type UpdateForwardContract = {
  id: number;
  reporting_unrealized_pnl: number;
  reporting_realized_pnl: number;
  // if some properties are null, it means some actions were not triggered (such as delivery) and so a contract that has
  // a null delivery_timestamp is still ongoing.
  delivery_timestamp: string | null;
  close_price: number | null;
  closed_by: number | null;
};

export type PlayerHistory = {
  portfolio_return: number;
  timestamp: string;
};

export type PlayerHistorySocketResult = PlayerHistory & {
  player: number;
};

export type OpenOrdersErrorType = {
  // id: number;
  timestamp: string;
  message: string;
};

export type ExchangeHistory = {
  transaction: number;
  base_currency: number;
  variable_currency: number;
  amount: number;
  exchange_rate: number;
  value: number;
  fee: number;
  underlying_asset: number;

  timestamp: string;
};

export type InvestmentProfile = {
  risk_attitude: number;
  loss_tolerance: number;
  investment_horizon: number;
  investment_goal: number;
};

export type Person = {
  id: number;
  name: string;
};

export type User = {
  username: string;
  persons: number[];
};

export type Player = {
  id: number;
  user: User;
  session: number;
  player_type: number;
  no_of_executed_orders: number;
  deals: number;
  name: string;
  online: boolean;
  investment_profile: InvestmentProfile;
  wallets: Wallet[];
  strategic_asset_allocations: StrategicAssetAllocation[];
  tactical_asset_allocations: TacticalAssetAllocation[];
  region_allocations: RegionAllocation[];
  metrics: Metrics[];
  last_metrics: Metrics;
  benchmark_compositions: BenchmarkComposition[];
  badges: PlayerBadge[];
  financial_instruments: IndexedObject<FinancialInstrument>;
  deposits: DepositInstrument[];
  deposits_history: DepositHistory[];

  market_value: number;
  portfolio_return: number;
  pnl_sum: number;
  fx_return: number;
  fx_return_percent: number;

  last_market_value: number;
  last_portfolio_return: number;
  last_pnl_sum: number;
  last_fx_return: number;

  risk_weighted_value: number;
  coins: number;
  player_history: PlayerHistory[];
  exchange_history: ExchangeHistory[];
  loan_rate_offer_options: LoanRateOfferOptions[];
  other_players: OtherPlayers[];
  sent_rfq_requests: RFQRequest[];
  rfq_responses: RFQResponse[]; // received requests from other players which await response
  interbank_deals: InterBankDeal[];
  interbank_interest: number;
  forward_contracts: {
    items: IndexedObject<ForwardContract>;
    fetched: boolean;
    fetching?: boolean;
  };
  forward_oda: {
    items: IndexedObject<OdaForward>;
    fetched: boolean;
    fetching?: boolean;
  };

  remaining_short_sell_amount_limit: number;
  remaining_fx_amount_limit: number; // deprecated
  remaining_reporting_absolute_hedge_amount: number;
  remaining_reporting_absolute_bond_leverage: number;

  realized_accrued_interest: number;
  player_cashed_coupons: number;
  portfolio_name: string;
  open_order_errors: OpenOrdersErrorType[];

  insights_reports?: number[];
  roadmap_items: number[];

  dividends?: Dividend[];

  fetched_data: boolean;
  fetched_success: boolean;
  fetched_blotter: boolean;
  fetching_blotter: boolean;
  retrieved_full?: boolean;

  htm_portfolio_dv01: number;
  htm_portfolio_duration: number;

  afs_portfolio_dv01: number;
  afs_portfolio_duration: number;

  // client_money overview cards
  trading_book: number;
  interbank_amount: number;
  total_repoed_amount: number;
  client_interest: number;
  paid_lombard_interest: number;
  paid_repo_interest: number;
  trading_book_pnl: number;
};

export type UpdateSALPayload = {
  strategic_asset_allocations: [
    {
      asset_class: number;
      percent: number;
    }
  ];
};

export const INTERBANK_BORROW = 0;
export const INTERBANK_LEND = 1;
