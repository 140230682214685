import { appManager, AppState } from '../manager';
import {
  addElementToIndexedArray,
  addElementToIndexedArrayImmutable,
} from 'services/indexedObject';

import * as types from '../types';
import {
  CreateCDSContract,
  CreateForwardContract,
  CreateRepoContract,
  CreateTBillContract,
  FinancialInstrument,
  ForwardContract,
  FwdOda,
  RepoContractType,
  TBillContractType,
  Wallet,
} from '../types';

function createOrderReducer(state: AppState, result: types.CreateOrderResult) {
  const {
    order,
    wallet_amount,
    instrument_amount,
    collateral_amount,
    close_previous_orders,
    remaining_short_sell_amount_limit,
    avg_open_price,
    reporting_unrealized_pnl,
    reporting_realized_pnl,
    available_repoable_amount,
  } = result;

  addElementToIndexedArray(state.orders.items, order);

  if (!state.player.item.financial_instruments) return;

  const instrument =
    state.player.item.financial_instruments[order.financial_instrument];

  instrument.orders.push(order.id);

  if (close_previous_orders) {
    try {
      instrument.oda = (undefined as unknown) as types.ODA;
      instrument.orders.forEach(
        order => (state.orders.items[order].closed = true)
      );
    } catch {}
  }

  const { underlying_asset } = instrument;
  const { variable_currency } = state.underlying_assets.items[underlying_asset];

  const wallet = state.player.item.wallets.findIndex(
    item => item.currency === variable_currency
  );

  if (typeof wallet_amount !== 'undefined') {
    state.player.item.wallets[wallet].amount = wallet_amount;
  }
  if (typeof collateral_amount !== 'undefined') {
    state.player.item.wallets[wallet].collateral_amount = collateral_amount;
  }
  if (typeof instrument_amount !== 'undefined') {
    instrument.amount = instrument_amount;
  }
  if (typeof avg_open_price !== 'undefined') {
    instrument.avg_open_price = avg_open_price;
  }
  if (typeof reporting_realized_pnl !== 'undefined') {
    instrument.reporting_realized_pnl = reporting_realized_pnl;
  }
  if (typeof reporting_unrealized_pnl !== 'undefined') {
    instrument.reporting_unrealized_pnl = reporting_unrealized_pnl;
  }
  if (typeof available_repoable_amount !== 'undefined') {
    instrument.available_repoable_amount = available_repoable_amount;
  }

  if (typeof remaining_short_sell_amount_limit !== 'undefined') {
    state.player.item.remaining_short_sell_amount_limit = remaining_short_sell_amount_limit;
  }

  state.orders.last = order;
}

export const createOrder = appManager.createApi<
  types.CreateOrderPayload,
  types.CreateOrderResult,
  AppState
>('CREATE_ORDER', {
  path: '/orders',
  method: 'POST',
  successReducer: createOrderReducer,
});

export const buyForwardContract = appManager.createApi<
  types.CreateForwardContract,
  {
    new_fin_amount: number;
    contract: types.ForwardContract;
  },
  AppState
>('BUY_FORWARD_CONTRACT', {
  path: '/forward_contracts/buy_forward_contract',
  method: 'POST',
  successReducer: (state, result) => {
    state.player.item.forward_contracts.items = addElementToIndexedArrayImmutable(
      state.player.item.forward_contracts.items,
      result.contract
    );

    state.player.item.financial_instruments[
      result.contract.financial_instrument
    ].amount = result.new_fin_amount;

    state.player.item.financial_instruments[
      result.contract.financial_instrument
    ].forward_contracts.push(result.contract.id);
  },
});
export const setForwardContractOda = appManager.createApi<
  types.FwdOda,
  {
    oda: types.OdaForward;
  },
  AppState
>('SET_FWD_ODA', {
  path: '/forward_contracts/set_forward_contract_oda',
  method: 'POST',
  successReducer: (state, result, payload) => {
    const contract =
      state.player.item.forward_contracts.items[payload.contract_id];
    state.player.item.forward_contracts.items[payload.contract_id] = {
      ...contract,
      oda: result.oda.id,
    };
    state.player.item.forward_oda.items = {
      ...state.player.item.forward_oda.items,
      [result.oda.id]: result.oda,
    };
  },
});

export const updateForwardContractOda = appManager.createApi<
  types.FwdOda,
  {
    oda: types.OdaForward;
  },
  AppState
>('UPDATE_FWD_ODA', {
  path: '/forward_contracts/update_forward_contract_oda',
  method: 'POST',
  successReducer: (state, result, payload) => {
    const contract =
      state.player.item.forward_contracts.items[payload.contract_id];
    if (contract.oda) {
      state.player.item.forward_oda.items[contract.oda].stop_loss_price =
        result.oda.stop_loss_price;
      state.player.item.forward_oda.items[contract.oda].take_profit_price =
        result.oda.take_profit_price;
    }
  },
});

export const deleteForwardContractOda = appManager.createApi<
  { contract_id: number },
  // {
  //   new_fin_amount: number;
  //   contract: types.ForwardContract;
  // },
  unknown,
  // types.ForwardContract,
  AppState
>('DELETE_FWD_ODA', {
  path: '/forward_contracts/delete_forward_contract_oda',
  method: 'POST',
  successReducer: (state, result, payload) => {
    const contract =
      state.player.item.forward_contracts.items[payload.contract_id];
    state.player.item.forward_contracts.items[payload.contract_id] = {
      ...contract,
      oda: null,
    };
  },
});

export const enterCDSContract = appManager.createApi<
  types.CreateCDSContract,
  {
    new_fin_amount: number;
    contract: types.CDSContractType;
  },
  // types.ForwardContract,
  AppState
>('ENTER_CDS_CONTRACT', {
  path: '/cds/enter_cds_contract',
  method: 'POST',
  successReducer: (state, result) => {
    state.player.item.financial_instruments[
      result.contract.financial_instrument
    ].cds_contracts.push(result.contract);
    state.player.item.financial_instruments[
      result.contract.financial_instrument
    ].amount = result.new_fin_amount;
  },
});

export const enterTBillContract = appManager.createApi<
  types.CreateTBillContract,
  {
    updated_wallet: Wallet;
    updated_fin: FinancialInstrument;
  },
  // types.ForwardContract,
  AppState
>('ENTER_TBILL_CONTRACT', {
  path: '/tbills/enter_tbill_contract',
  method: 'POST',
  successReducer: (state, result) => {
    state.player.item.financial_instruments[result.updated_fin.id] =
      result.updated_fin;

    const walletIndex = state.player.item.wallets.findIndex(
      existing_wallet => existing_wallet.id === result.updated_wallet.id
    );
    state.player.item.wallets[walletIndex] = result.updated_wallet;
  },
});

export const enterRepoContract = appManager.createApi<
  types.CreateRepoContract,
  {
    wallet_id: number;
    new_wallet_amount: number;
    repoed_amount: number;
    available_repoable_amount: number;
    contract: types.RepoContractType;
  },
  // types.ForwardContract,
  AppState
>('ENTER_REPO_CONTRACT', {
  path: '/repo/enter_repo_contract',
  method: 'POST',
  successReducer: (state, result) => {
    state.player.item.financial_instruments[
      result.contract.financial_instrument
    ].repo_contracts.push(result.contract);
    state.player.item.financial_instruments[
      result.contract.repoed_instrument
    ].available_repoable_amount = result.available_repoable_amount;
    state.player.item.financial_instruments[
      result.contract.repoed_instrument
    ].repoed_amount = result.repoed_amount;
    const wallet = state.player.item.wallets.find(
      wallet => wallet.id === result.wallet_id
    );
    if (wallet) {
      wallet.amount = result.new_wallet_amount;
    }
  },
});

export const closeForwardContract = appManager.createApi<
  { contract_id: number },
  {
    new_fin_unrlzd: number;
    new_fin_amount: number;
    closing_contract: types.ForwardContract;
    closed_contract: types.ForwardContract;
  },
  // types.ForwardContract,
  AppState
>('CLOSE_FORWARD_CONTRACT', {
  path: '/forward_contracts/close_forward_contract',
  method: 'POST',
  successReducer: (state, result) => {
    // state.player.item.financial_instruments[
    //   result.contract.financial_instrument
    // ].forward_contracts.push(result.contract);

    state.player.item.forward_contracts.items = addElementToIndexedArrayImmutable(
      state.player.item.forward_contracts.items,
      result.closing_contract
    );

    state.player.item.forward_contracts.items[result.closed_contract.id] =
      result.closed_contract;

    state.player.item.financial_instruments[
      result.closing_contract.financial_instrument
    ].forward_contracts.push(result.closing_contract.id);

    state.player.item.financial_instruments[
      result.closing_contract.financial_instrument
    ].amount = result.new_fin_amount;

    state.player.item.financial_instruments[
      result.closing_contract.financial_instrument
    ].reporting_unrealized_pnl = result.new_fin_unrlzd;
  },
});

appManager.createSocketListener<
  {
    new_fin_unrlzd: number;
    new_fin_amount: number;
    closing_contract: types.ForwardContract;
    closed_contract: types.ForwardContract;
  },
  AppState
>('fwd_contract_closed_by_oda', (state, result) => {
  console.log('fwd_contract_closed_by_oda: ', result);

  state.player.item.forward_contracts.items = addElementToIndexedArrayImmutable(
    state.player.item.forward_contracts.items,
    result.closing_contract
  );

  state.player.item.forward_contracts.items[result.closed_contract.id] =
    result.closed_contract;

  state.player.item.financial_instruments[
    result.closing_contract.financial_instrument
  ].forward_contracts.push(result.closing_contract.id);

  state.player.item.financial_instruments[
    result.closing_contract.financial_instrument
  ].amount = result.new_fin_amount;

  state.player.item.financial_instruments[
    result.closing_contract.financial_instrument
  ].reporting_unrealized_pnl = result.new_fin_unrlzd;
});

export const sellTBillContract = appManager.createApi<
  { contract_id: number },
  {
    updated_wallet: Wallet;
    updated_fin: FinancialInstrument;
  },
  // types.ForwardContract,
  AppState
>('SELL_TBILL_CONTRACT', {
  path: '/tbills/sell_tbill_contract',
  method: 'POST',
  successReducer: (state, result) => {
    state.player.item.financial_instruments[result.updated_fin.id] =
      result.updated_fin;

    const walletIndex = state.player.item.wallets.findIndex(
      existing_wallet => existing_wallet.id === result.updated_wallet.id
    );
    state.player.item.wallets[walletIndex] = result.updated_wallet;
  },
});

export const forceForwardContractFromAdmin = appManager.createApi<
  types.ForceContractFromAdmin,
  unknown,
  // types.ForwardContract,
  AppState
>('FORCE_BUY_FORWARD_CONTRACT', {
  path: '/forward_contracts/force_buy_forward_contract_from_admin',
  method: 'POST',
  successReducer: (state, result) => {
    // console.log(result);
  },
});

appManager.createSocketListener<
  {
    remaining_reporting_absolute_hedge_amount: number;
  },
  AppState
>(
  'update_ramaining_hedge_amount',
  (state, { remaining_reporting_absolute_hedge_amount }) => {
    state.player.item.remaining_reporting_absolute_hedge_amount = remaining_reporting_absolute_hedge_amount;
  }
);

appManager.createSocketListener<
  {
    repo_contract: RepoContractType;
  },
  AppState
>('repo_settled', (state, result) => {
  // console.log('repo_settled --------');
  // console.log('msg contract: ', result.repo_contract);

  const repo_instrument =
    state.player.item.financial_instruments[
      result.repo_contract.financial_instrument
    ];
  // console.log('repo fin: ', repo_instrument);
  const repo_contract_state_index = repo_instrument.repo_contracts.findIndex(
    contract => contract.id === result.repo_contract.id
  );
  // console.log('repo_contract_state_index: ', repo_contract_state_index);
  if (repo_contract_state_index !== -1) {
    repo_instrument.repo_contracts[repo_contract_state_index] =
      result.repo_contract;
  }
});

appManager.createSocketListener<
  {
    remaining_reporting_absolute_bond_leverage: number;
  },
  AppState
>(
  'update_ramaining_bond_leverage',
  (state, { remaining_reporting_absolute_bond_leverage }) => {
    state.player.item.remaining_reporting_absolute_bond_leverage = remaining_reporting_absolute_bond_leverage;
  }
);

export const createODA = appManager.createApi<
  {
    financial_instrument: number;
    stop_loss_price: number;
    take_profit_price: number;
  },
  { oda: types.ODA | null; orders: types.CreateOrderResult[] },
  AppState
>('CREATE_ODA', {
  path: '/orders/create_oda',
  method: 'POST',
  successReducer: (state, result, payload) => {
    result.orders.forEach(res => createOrderReducer(state, res));

    const { financial_instrument } = payload;
    if (result.oda) {
      state.player.item.financial_instruments[financial_instrument].oda =
        result.oda;
    }
  },
});

export const updateOda = appManager.createApi<
  {
    financial_instrument: number;
    stop_loss_price: number;
    take_profit_price: number;
  },
  unknown,
  AppState
>('UPDATE_ODA', {
  path: '/orders/update_oda',
  method: 'POST',
  successReducer() {
    /*empty*/
  },
});

export const cancelOrder = appManager.createApi<
  { id: number },
  unknown,
  AppState
>('CANCEL_ORDER', {
  path: '/orders/:id/cancel',
  method: 'POST',
  successReducer() {
    /*empty*/
  },
});

export const cancelOrders = appManager.createApi<unknown, unknown, AppState>(
  'CANCEL_ORDERS',
  {
    path: '/orders/cancel_orders',
    method: 'POST',
    successReducer() {
      /*empty*/
    },
  }
);

appManager.createSocketListener<types.CreateOrderResult, AppState>(
  'order_executed',
  createOrderReducer
);

appManager.createSocketListener<types.Order, AppState>(
  'order_updated',
  (state, order) => {
    const orders = state.orders.items;
    const { id } = order;

    orders[id] = { ...orders[id], ...order };
  }
);

appManager.createSocketListener<
  {
    wallet_amount: number;
    collateral_amount: number;
    order: types.Order;
  },
  AppState
>('order_canceled', (state, { wallet_amount, collateral_amount, order }) => {
  const oldOrder = state.orders.items[order.id];

  if (!oldOrder) return;

  const { underlying_asset } = state.player.item.financial_instruments[
    oldOrder.financial_instrument
  ];
  const { variable_currency } = state.underlying_assets.items[underlying_asset];

  const wallet = state.player.item.wallets.findIndex(
    item => item.currency === variable_currency
  );
  state.player.item.wallets[wallet].amount = wallet_amount;
  state.player.item.wallets[wallet].collateral_amount = collateral_amount;

  const instrument =
    state.player.item.financial_instruments[oldOrder.financial_instrument];

  instrument.orders = instrument.orders.filter(o => o !== order.id);

  state.orders.items[order.id] = order; // Updates order in redux state with the new order that came from backend through socket (and which now has a status of canceled). This triggers a rerender in openOrders and Blotter
});

appManager.createSocketListener<
  {
    id: number;
  },
  AppState
>('oda_canceled', (state, { id }) => {
  delete state.player.item.financial_instruments[id].oda;
});
