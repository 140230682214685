import React from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { REGIONS, selectors } from 'state';
import { formatters } from 'services';

const Valuation: React.FC = () => {
  const equities = useSelector(selectors.equityList);
  const currencies = useSelector(selectors.currencies);

  const volatilities = equities
    .map(e => e.volatility)
    .filter(v => v !== undefined) as number[];

  const maxVolatility = volatilities.length ? Math.max(...volatilities) : 0;

  return (
    <div>
      <Table
        rowKey="id"
        dataSource={equities}
        pagination={{
          defaultPageSize: 20,
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            sorter(a, b) {
              return a.name.localeCompare(b.name);
            },
          },
          {
            title: 'Region',
            dataIndex: 'region',
            render(region: keyof typeof REGIONS) {
              return REGIONS[region];
            },
            sorter(a, b) {
              return a.region - b.region;
            },
            filters: Object.entries(REGIONS).map(([key, value]) => {
              return {
                text: value,
                value: Number(key),
              };
            }),
            onFilter: (value, record) => value === record.region,
          },
          {
            title: 'BBG Ticker',
            dataIndex: 'ticker',
            sorter(a, b) {
              return a.ticker.localeCompare(b.ticker);
            },
          },
          {
            title: 'CCY',
            dataIndex: 'variable_currency',
            render(ccy) {
              return currencies[ccy]?.symbol || '-';
            },
            sorter(a, b) {
              return a.variable_currency - b.variable_currency;
            },
            filters: Object.values(currencies).map(ccy => {
              return {
                text: ccy.symbol,
                value: ccy.id,
              };
            }),
            onFilter: (value, record) => value === record.variable_currency,
          },
          {
            title: '52w. Low-High',
            dataIndex: '',
            align: 'right',
            render(text, record) {
              if (
                typeof record.w52_high === 'undefined' ||
                typeof record.w52_low === 'undefined'
              ) {
                return '-';
              }
              const low = formatters.commas2Digits(record.w52_low);
              const high = formatters.commas2Digits(record.w52_high);

              return `${low} - ${high}`;
            },
          },
          // { title: 'MKT. Cap.(B)', dataIndex: '' },
          {
            title: 'P/E Ratio',
            dataIndex: 'pe_ratio',
            align: 'right',
            render(value) {
              if (typeof value !== 'number') {
                return '-';
              }
              let opacity = 0;
              if (value <= 1) {
                opacity = 1;
              } else if (value >= 25) {
                opacity = 0;
              } else {
                opacity = 1 - value / 25;
              }

              let color = 'inherit';
              if (opacity >= 0.9) {
                color = '#fff';
              }

              return (
                <div
                  style={{
                    backgroundColor: `rgba(22,119,255,${opacity})`,
                    color,
                    marginTop: '-5px',
                    marginLeft: '-5px',
                    marginRight: '-5px',
                    marginBottom: '-5px',
                    padding: '5px',
                  }}
                >
                  {formatters.commas2Digits(value)}
                </div>
              );
            },
            sorter(a, b) {
              return (a.pe_ratio ?? 0) - (b.pe_ratio ?? 0);
            },
          },
          {
            title: 'Beta 1Y (Monthly)',
            dataIndex: 'beta',
            align: 'right',
            render(value) {
              if (value == undefined) {
                return '-';
              }
              return formatters.commas2Digits(value);
            },
            sorter(a, b) {
              return (a.beta ?? 0) - (b.beta ?? 0);
            },
          },
          {
            title: 'Earnings',
            dataIndex: 'earnings',
            align: 'right',
            render(value) {
              return formatters.commas2Digits(value);
            },
            sorter(a, b) {
              return (a.earnings ?? 0) - (b.earnings ?? 0);
            },
          },
          {
            title: 'Volatility (%)',
            dataIndex: 'volatility',
            width: '100px',
            align: 'right',
            render(value) {
              if (value == undefined || maxVolatility === 0) {
                return '-';
              }

              const percent = (value / maxVolatility) * 100;

              return (
                <div
                  style={{
                    display: 'grid',
                  }}
                >
                  <div
                    style={{
                      gridArea: '1 / 1',
                      marginTop: '-5px',
                      marginLeft: '-5px',
                      marginBottom: '-5px',
                      backgroundColor: `rgb(22,119,255)`,
                      width: `${percent}%`,
                    }}
                  />
                  <div
                    style={{
                      gridArea: '1 / 1',
                      color: percent >= 35 ? '#fff' : 'inherit',
                    }}
                  >
                    {formatters.percent2Digit(value)}
                  </div>
                </div>
              );
            },
            sorter(a, b) {
              return (a.volatility ?? 0) - (b.volatility ?? 0);
            },
          },
          {
            title: 'Sector',
            dataIndex: 'sector',
            sorter(a, b) {
              return (a.sector ?? '').localeCompare(b.sector || '');
            },
          },
        ]}
      />
    </div>
  );
};

export default Valuation;
