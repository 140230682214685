import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Popconfirm, Tooltip } from 'antd';
import { computeBeta, computeVolatility, deleteCorrelationValues } from 'state';

const Equities: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <Card title="Quick Actions">
        <div style={{ display: 'flex', gap: 10 }}>
          <Button
            type="primary"
            onClick={() => {
              dispatch(computeVolatility({}));
            }}
          >
            Compute Volatility
          </Button>
          <Button
            type="primary"
            onClick={() => {
              dispatch(computeBeta({}));
            }}
          >
            Compute Beta
          </Button>
          <Tooltip
            title={
              <div>
                <div>
                  This will delete all correlation values in the system.
                </div>
                <div>
                  (The next time somebody clicks compute, the values will be
                  recomputed on demand)
                </div>
              </div>
            }
          >
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => {
                dispatch(deleteCorrelationValues({}));
              }}
            >
              <Button type="primary" danger>
                Delete Correlation Values
              </Button>
            </Popconfirm>
          </Tooltip>
        </div>
      </Card>
    </div>
  );
};

export default Equities;
